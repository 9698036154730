import Vue from 'vue'
import { Component } from 'vue-property-decorator'

export interface IFeatures {
  'dependency-viewer': boolean,
}

export interface IFeaturesMixin {
  $featureFlags: IFeatures
  $feature(key: keyof IFeatures): boolean
}

@Component
export default class extends Vue implements IFeaturesMixin {
  get $featureOnQuery () {
    return this.$queryArray('feature_on')
  }

  get $featureOffQuery () {
    return this.$queryArray('feature_off')
  }

  get $featureFlags (): IFeatures {
    return {
      'dependency-viewer': !this.$featureOffQuery.includes('dependency-viewer')
    }
  }

  $feature (key: keyof IFeatures) {
    if (this.$featureFlags[key] !== undefined) {
      return !!this.$featureFlags[key]
    } else {
      throw new Error(`Feature flag ${key} not found`)
    }
  }
}
