import { RouteConfig } from 'vue-router'

import AppbarLogoDense from '@/components/appbar/logo-dense.vue'
import AccuracyAppbar from '@/modules/accuracy/components/appbar/index.vue'
import CommentsAppbar from '@/modules/comment/components/appbar/index.vue'
import OrganizationUpgrade from '@/modules/organization/components/appbar/upgrade.vue'
import RecommendationsAppbar from '@/modules/recommendation/components/appbar/index.vue'
import ShareAppbar from '@/modules/share/components/appbar/share.vue'
import UserAppbarAvatars from '@/modules/user/components/appbar/avatars.vue'

import HelpAppbar from './components/appbar/help.vue'
import AppbarInfo from './components/appbar/info.vue'

const meta = {
  background: '#323435',
  disableScroll: true,
  hasAppbar: true,
  requiresAuth: true,
  requiresSocket: true
}

const appbar = {
  'appbar-left-1': AppbarLogoDense,
  'appbar-left-2': AppbarInfo,
  'appbar-right-1': ShareAppbar,
  'appbar-right-2': UserAppbarAvatars,
  'appbar-right-3': HelpAppbar,
  'appbar-right-4': AccuracyAppbar,
  'appbar-right-5': RecommendationsAppbar,
  'appbar-right-6': CommentsAppbar,
  'appbar-right-7': OrganizationUpgrade
}

const routes: RouteConfig[] = [
  {
    components: {
      default: () => import(/* webpackChunkName: "editor" */ './views/editor.vue'),
      ...appbar
    },
    meta,
    name: 'editor-diagram',
    path: '/landscapes/:landscapeId/versions/latest/diagrams/editor'
  },

  /**
   * Deprecated
   */

  {
    path: '/landscapes/:landscapeId/model',
    redirect: to => ({
      name: 'model-objects',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      }
    })
  },
  {
    path: '/landscapes/:landscapeId/diagrams',
    redirect: to => ({
      name: 'editor-diagram',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      },
      query: to.query
    })
  },
  {
    path: '/projects/:landscapeId/model',
    redirect: to => ({
      name: 'model-objects',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      }
    })
  },
  {
    path: '/projects/:landscapeId/diagrams',
    redirect: to => ({
      name: 'editor-diagram',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      },
      query: to.query
    })
  },
  {
    path: '/projects/:landscapeId/views',
    redirect: to => ({
      name: 'editor-diagram',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      },
      query: to.query
    })
  }
]

export default routes
