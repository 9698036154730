
import { DiagramType, modelObjectDiagramType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import ModelObjectFamilyMenu from '@/modules/model/components/objects/object-family-menu.vue'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import VersionMenu from '@/modules/version/components/menu/index.vue'
import { VersionModule } from '@/modules/version/store'

const LEVEL_ICONS: Record<DiagramType, string> = {
  'app-diagram': require('../../assets/level-2.svg'),
  'component-diagram': require('../../assets/level-3.svg'),
  'context-diagram': require('../../assets/level-1.svg')
}

@Component({
  components: {
    ModelObjectFamilyMenu,
    VersionMenu
  },
  name: 'EditorAppbarInfo'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions?.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentVersionLatest () {
    return this.currentVersion?.tags.includes('latest')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get shareLinkPreventNavigation () {
    return !!this.shareModule.shareLinkOptions?.preventNavigation && this.shareModule.shareLinkOptions.shareLinkId === this.currentShareLink?.id
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramType () {
    return this.currentDiagram?.type
  }

  get currentDiagramLevel () {
    switch (this.currentDiagramType) {
      case 'context-diagram': return 1
      case 'app-diagram': return 2
      case 'component-diagram': return 3
      default: return ''
    }
  }

  get currentDiagramIcon () {
    return this.currentDiagramType ? LEVEL_ICONS[this.currentDiagramType] : undefined
  }

  get currentDiagramLabel () {
    switch (this.currentDiagramType) {
      case 'context-diagram': return 'Context diagrams'
      case 'app-diagram': return 'App diagrams'
      case 'component-diagram': return 'Component diagrams'
      default: return ''
    }
  }

  get domains () {
    return Object.values(this.domainModule.domains)
  }

  get domainsActive () {
    return this.domains.length >= 2 || !!this.domains[0].name
  }

  get currentDomain () {
    return this.domains.find(o => o.handleId === this.currentDomainHandleId)
  }

  get currentModelObject () {
    return Object.values(this.modelModule.objects).find(o => {
      if (this.currentModelHandleId) {
        return o.handleId === this.currentModelHandleId
      } else if (this.currentDomain) {
        return o.domainId === this.currentDomain.id && o.type === 'root'
      } else {
        return false
      }
    })
  }

  get currentModelObjectDomain () {
    return this.currentModelObject ? this.domainModule.domains[this.currentModelObject.domainId] : undefined
  }

  get currentVersionModelObjectDomain () {
    return this.currentModelObject ? this.domainModule.domains[this.currentModelObject.domainId] : undefined
  }

  get levelModelObject () {
    if (this.currentVersionLatest) {
      if (this.currentModelObject && this.currentModelObject.type === 'root' && this.currentModelObjectDomain && this.domainsActive) {
        return this.currentModelObjectDomain
      } else if (this.currentModelObject && this.currentModelObject.type !== 'root') {
        return this.currentModelObject
      }
    } else {
      if (this.currentModelObject && this.currentModelObject.type === 'root' && this.currentVersionModelObjectDomain && this.domainsActive) {
        return this.currentVersionModelObjectDomain
      } else if (this.currentModelObject && this.currentModelObject.type !== 'root') {
        return this.currentModelObject
      }
    }
  }

  get levelText () {
    if (this.currentVersionLatest) {
      if (this.currentModelObject && this.currentModelObject.type === 'root' && this.currentModelObjectDomain && this.domainsActive) {
        return this.currentModelObjectDomain.name || 'Domain'
      } else if (this.currentModelObject && this.currentModelObject.type !== 'root') {
        return this.currentModelObject.name || `${this.currentModelObject.type.slice(0, 1).toUpperCase()}${this.currentModelObject.type.slice(1)}`
      }
    } else {
      if (this.currentModelObject && this.currentModelObject.type === 'root' && this.currentVersionModelObjectDomain && this.domainsActive) {
        return this.currentVersionModelObjectDomain.name || 'Domain'
      } else if (this.currentModelObject && this.currentModelObject.type !== 'root') {
        return this.currentModelObject.name || `${this.currentModelObject.type.slice(0, 1).toUpperCase()}${this.currentModelObject.type.slice(1)}`
      }
    }
  }

  get levelLinks () {
    const currentModel = this.currentModelObject
    if (!currentModel) {
      return []
    }

    return [currentModel.id, ...this.modelModule.objects[currentModel.id]?.parentIds ?? []]
      .reverse()
      .flatMap(o => {
        const obj = this.modelModule.objects[o]
        if (obj) {
          const diagramType: DiagramType | undefined = modelObjectDiagramType[obj.type]
          return [{
            active: obj.id === currentModel.id,
            click: async () => {
              const explore = await this.diagramModule.diagramsExplore({
                landscapeId: this.currentLandscapeId,
                modelId: obj.id,
                versionId: this.currentVersionId
              })
              if (explore) {
                this.diagramModule.diagramAction({
                  action: 'zoom',
                  diagramId: explore.diagram.id,
                  landscapeId: this.currentLandscapeId,
                  versionId: this.currentVersionId
                })

                await this.$pushQuery({
                  connection: undefined,
                  diagram: explore.diagram.handleId,
                  flow: undefined,
                  flow_parent: undefined,
                  flow_path: undefined,
                  flow_step: undefined,
                  model: explore.modelObject.handleId,
                  object: undefined,
                  x1: undefined,
                  x2: undefined,
                  y1: undefined,
                  y2: undefined
                })
              }
            },
            icon: diagramType ? LEVEL_ICONS[diagramType] : undefined,
            id: o
          }]
        } else {
          return []
        }
      })
  }
}
