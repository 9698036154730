import { Assets, DiagramTool } from '@icepanel/app-canvas'
import { EditorLocation, EditorLocationUpdate, EditorTyping, EditorTypingUpdate, LandscapesService, ModelConnection, ModelObject, SocketClientEditorSubscribeBody, TaskListRevertable, TaskProposed } from '@icepanel/platform-api-client'
import PQueue from 'p-queue'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'
import { InterstitialType } from '@/modules/interstitial/store'
import { ServerEvents, Socket } from '@/plugins/socket'

import * as diff from './helpers/diff'

const MAX_ACTION_HISTORY = 50

export interface IEditorModule {
  toolbarSelection: DiagramTool
  objectCreateFilterName: string
  connectionCreateFilterName: string

  resourceLoaded: boolean

  taskQueue: Record<string, PQueue>
  taskLists: TaskListRevertable[]
  taskListsCursor: number

  editorSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, unsubscribe: () => void }>

  stepHoverIds: string[]
  highlightIds: string[]
  hidePreviewIds: string[]
  unhidePreviewIds: string[]
  focusPreviewIds: string[]
  unfocusPreviewIds: string[]

  overlayBarTagGroups: Record<string, string>

  diagramReviewMenu: boolean

  locations: Record<string, EditorLocation>
  typing: Record<string, EditorTyping>
}

const name = 'editor'

@Module({
  name,
  namespaced: true
})
export class EditorModule extends VuexModule implements IEditorModule {
  static namespace = name

  toolbarSelection: DiagramTool = 'move'
  objectCreateFilterName = ''
  connectionCreateFilterName = ''

  resourceLoaded = false

  taskQueue: Record<string, PQueue> = {}
  taskLists: TaskListRevertable[] = []
  taskListsCursor = 0

  editorSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, unsubscribe:() => void }>()

  stepHoverIds: string[] = []
  highlightIds: string[] = []
  hidePreviewIds: string[] = []
  unhidePreviewIds: string[] = []
  focusPreviewIds: string[] = []
  unfocusPreviewIds: string[] = []

  overlayBarTagGroups: Record<string, string> = {}

  diagramReviewMenu = false

  locations: Record<string, EditorLocation> = {}
  typing: Record<string, EditorTyping> = {}

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  get draftChanges () {
    const objectsCurrent: Record<string, ModelObject> = this.context.rootState.model.objectsCurrent
    const connectionsCurrent: Record<string, ModelConnection> = this.context.rootState.model.connectionsCurrent

    const objects: Record<string, ModelObject> = this.context.rootGetters['model/objects']
    const connections: Record<string, ModelConnection> = this.context.rootGetters['model/connections']

    return diff.changes({
      modelConnections: connectionsCurrent,
      modelObjects: objectsCurrent
    }, {
      modelConnections: connections,
      modelObjects: objects
    })
  }

  get tasksProposedDryRun () {
    return (tasksProposed: TaskProposed[]) => {
      const objects: Record<string, ModelObject> = { ...this.context.rootState.model.objectsCurrent }
      const connections: Record<string, ModelConnection> = { ...this.context.rootState.model.connectionsCurrent }

      return tasksProposed.map((o): (TaskProposed & { error?: string }) => {
        if (o.task.type === 'model-object-create') {
          if (objects[o.task.id]) {
            return {
              ...o,
              error: `Could not create ${o.task.props.name || o.task.props.type} as it already exists`
            }
          }
          if (o.task.props.parentId && !objects[o.task.props.parentId]) {
            return {
              ...o,
              error: `Could not create ${o.task.props.name || o.task.props.type} as the parent no longer exists`
            }
          }

          const { object } = this.context.rootGetters['model/generateObject']('', '', o.task.props, o.task.id)
          objects[object.id] = object
          return o
        } else if (o.task.type === 'model-connection-create') {
          if (connections[o.task.id]) {
            return {
              ...o,
              error: `Could not create ${o.task.props.name || 'connection'} as it already exists`
            }
          }
          if (o.task.props.originId && !objects[o.task.props.originId]) {
            return {
              ...o,
              error: `Could not create ${o.task.props.name || 'connection'} as the sender no longer exists`
            }
          }
          if (o.task.props.targetId && !objects[o.task.props.targetId]) {
            return {
              ...o,
              error: `Could not create ${o.task.props.name || 'connection'} as the receiver no longer exists`
            }
          }

          const { connection } = this.context.rootGetters['model/generateConnection']('', '', o.task.props, o.task.id)
          connections[connection.id] = connection
          return o
        } else if (o.task.type === 'model-object-update') {
          const updateObject = objects[o.task.id]
          if (!updateObject) {
            return {
              ...o,
              error: 'Could not update object as it no longer exists'
            }
          }
          if (o.task.props.parentId && !objects[o.task.props.parentId]) {
            return {
              ...o,
              error: `Could not update ${updateObject.name || updateObject.type} as the parent no longer exists`
            }
          }

          const { object } = this.context.rootGetters['model/generateObjectCommit'](o.task.id, o.task.props, objects[o.task.id])
          objects[object.id] = object
          return o
        } else if (o.task.type === 'model-connection-update') {
          const updateConnection = connections[o.task.id]
          if (!updateConnection) {
            return {
              ...o,
              error: 'Could not update connection as it no longer exists'
            }
          }
          if (o.task.props.originId && !objects[o.task.props.originId]) {
            return {
              ...o,
              error: `Could not update ${updateConnection.name || 'connection'} as the sender no longer exists`
            }
          }
          if (o.task.props.targetId && !objects[o.task.props.targetId]) {
            return {
              ...o,
              error: `Could not update ${updateConnection.name || 'connection'} as the receiver no longer exists`
            }
          }

          const { connection } = this.context.rootGetters['model/generateConnectionCommit'](o.task.id, o.task.props, connections[o.task.id])
          connections[connection.id] = connection
          return o
        } else if (o.task.type === 'model-object-delete') {
          const deleteObject = objects[o.task.id]
          if (!deleteObject) {
            return {
              ...o,
              error: 'Could not delete object as it no longer exists'
            }
          }

          delete objects[o.task.id]
          return o
        } else if (o.task.type === 'model-connection-delete') {
          const deleteConnection = connections[o.task.id]
          if (!deleteConnection) {
            return {
              ...o,
              error: 'Could not delete connection as it no longer exists'
            }
          }

          delete connections[o.task.id]
          return o
        } else {
          throw new Error(`Unexpected task type ${o.task.type} `)
        }
      })
    }
  }

  @Mutation
  setToolbarSelection (item: DiagramTool) {
    this.toolbarSelection = item
  }

  @Mutation
  setObjectCreateFilterName (name: string) {
    this.objectCreateFilterName = name
  }

  @Mutation
  setConnectionCreateFilterName (name: string) {
    this.connectionCreateFilterName = name
  }

  @Mutation
  setResourceLoaded (resourceLoaded: boolean) {
    this.resourceLoaded = resourceLoaded
  }

  @Mutation
  addTaskList (taskList: TaskListRevertable) {
    if (this.taskListsCursor > 0) {
      this.taskLists.splice(0, this.taskListsCursor)
    }
    this.taskListsCursor = 0
    this.taskLists = [
      taskList,
      ...this.taskLists
    ].slice(0, MAX_ACTION_HISTORY)
  }

  @Mutation
  setTaskListCursor (offset: number) {
    this.taskListsCursor = Math.max(0, Math.min(this.taskLists.length, this.taskListsCursor + offset))
  }

  @Mutation
  resetTaskLists () {
    this.taskLists = []
    this.taskListsCursor = 0
  }

  @Mutation
  setEditorSubscriptionStatus (...params: Parameters<typeof this.editorSubscriptionStatus.set>) {
    this.editorSubscriptionStatus.set(...params)
  }

  @Mutation
  setHighlight ({ id, enabled }: { id: string, enabled: boolean }) {
    if (enabled) {
      this.highlightIds = [...new Set([id, ...this.highlightIds])]
    } else {
      this.highlightIds = this.highlightIds.filter(o => o !== id)
    }
  }

  @Mutation
  setHidePreview ({ id, enabled }: { id: string, enabled: boolean }) {
    if (enabled) {
      if (!this.hidePreviewIds.includes(id)) {
        this.hidePreviewIds = [...this.hidePreviewIds, id]
      }
    } else {
      this.hidePreviewIds = this.hidePreviewIds.filter(o => o !== id)
    }
  }

  @Mutation
  setUnhidePreview ({ id, enabled }: { id: string, enabled: boolean }) {
    if (enabled) {
      if (!this.unhidePreviewIds.includes(id)) {
        this.unhidePreviewIds = [...this.unhidePreviewIds, id]
      }
    } else {
      this.unhidePreviewIds = this.unhidePreviewIds.filter(o => o !== id)
    }
  }

  @Mutation
  setFocusPreview ({ id, enabled }: { id: string, enabled: boolean }) {
    if (enabled) {
      if (!this.focusPreviewIds.includes(id)) {
        this.focusPreviewIds = [...this.focusPreviewIds, id]
      }
    } else {
      this.focusPreviewIds = this.focusPreviewIds.filter(o => o !== id)
    }
  }

  @Mutation
  setUnfocusPreview ({ id, enabled }: { id: string, enabled: boolean }) {
    if (enabled) {
      if (!this.unfocusPreviewIds.includes(id)) {
        this.unfocusPreviewIds = [...this.unfocusPreviewIds, id]
      }
    } else {
      this.unfocusPreviewIds = this.unfocusPreviewIds.filter(o => o !== id)
    }
  }

  @Mutation
  addStepHoverId (stepHoverId: string) {
    this.stepHoverIds = [...this.stepHoverIds, stepHoverId]
  }

  @Mutation
  removeStepHoverId (stepHoverId: string) {
    this.stepHoverIds = this.stepHoverIds.filter(o => o !== stepHoverId)
  }

  @Mutation
  setOverlayBarTagGroups (tagGroups: Record<string, string>) {
    this.overlayBarTagGroups = {
      ...this.overlayBarTagGroups,
      ...tagGroups
    }
  }

  @Mutation
  setLocations (locations: EditorLocation[]) {
    this.locations = {}
    locations.forEach(o => {
      this.locations[o.userId] = o
    })
  }

  @Mutation
  setLocation (location: EditorLocation) {
    this.locations = {
      ...this.locations,
      [location.userId]: location
    }
  }

  @Mutation
  removeLocation (userId: string) {
    Vue.delete(this.locations, userId)
  }

  @Mutation
  setTypings (typings: EditorTyping[]) {
    this.typing = {}
    typings.forEach(o => {
      this.typing[o.userId] = o
    })
  }

  @Mutation
  setTyping (typing: EditorTyping) {
    this.typing = {
      ...this.typing,
      [typing.userId]: typing
    }
  }

  @Mutation
  removeTyping (userId: string) {
    Vue.delete(this.typing, userId)
  }

  @Mutation
  editorUnsubscribe () {
    this.editorSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.editorSubscriptionStatus.successInfo?.unsubscribe?.()
    this.editorSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  addToTaskQueue ({ key, func }: { key?: string, func: (() => Promise<any>) | (() => Promise<any>)[] }) {
    const fn = async () => {
      try {
        if (func instanceof Array) {
          await Promise.all(func.map(o => o()))
        } else {
          await func()
        }
      } catch (err: any) {
        this.context.commit('interstitial/pushInterstitial', {
          message: err.body ? err.body.message : err.message,
          presentedType: InterstitialType.connectionIssue
        }, { root: true })
        throw err
      }
    }

    const taskQueueKey = key || 'default'
    if (this.taskQueue[taskQueueKey]) {
      this.taskQueue[taskQueueKey].add(fn)
    } else {
      if (this.taskQueue[taskQueueKey]) {
        this.taskQueue[taskQueueKey].removeAllListeners('idle')
        this.taskQueue[taskQueueKey].clear()
      }

      this.taskQueue[taskQueueKey] = new PQueue({
        concurrency: 1
      })
      this.taskQueue[taskQueueKey].add(fn)
      this.taskQueue[taskQueueKey].on('idle', () => {
        if (this.taskQueue[taskQueueKey]) {
          this.taskQueue[taskQueueKey].removeAllListeners('idle')
          this.taskQueue[taskQueueKey].clear()
          delete this.taskQueue[taskQueueKey]
        }
      })
    }
  }

  @Action({ rawError: true })
  async landscapeExportPdf ({ organizationId, landscapeId, versionId, filename, email, name }: { organizationId: string, landscapeId: string, versionId: string, filename: string, email?: string, name?: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { url } = await LandscapesService.landscapeExportPdf(authorization, organizationId, landscapeId, versionId, {
      email,
      filename,
      name
    })
    return url
  }

  @Action({ rawError: true })
  async loadResources () {
    Assets.add({
      alias: 'Lato',
      src: '/fonts/Lato.fnt'
    })
    Assets.add({
      alias: 'Sprites',
      src: '/sprites/data.json'
    })
    Assets.add({
      alias: 'Background',
      src: '/sprites/background.png'
    })
    await Assets.load([
      'Lato',
      'Sprites',
      'Background'
    ])
    this.context.commit('setResourceLoaded', true)
  }

  @Action({ rawError: true })
  async editorSubscribe (body: SocketClientEditorSubscribeBody & { reconnect: boolean }) {
    this.editorSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.editorSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setEditorSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect
    }))

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('editor-subscribe', {
        landscapeId: body.landscapeId
      }, (err, reply) => {
        if (reply) {
          const editorLocationsListener: ServerEvents['editor-locations'] = ({ locations, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setLocations', locations)

              this.context.commit('setEditorSubscriptionStatus', Status.success({
                landscapeId: body.landscapeId,
                subscriptionId: reply.subscriptionId,
                unsubscribe
              }))

              this.context.dispatch('socket/checkSocket', undefined, { root: true })
            }
          }
          const editorLocationAddedListener: ServerEvents['editor-location-added'] = ({ location, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setLocation', location)
            }
          }
          const editorLocationModifiedListener: ServerEvents['editor-location-modified'] = ({ location, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setLocation', location)
            }
          }

          const editorTypingListener: ServerEvents['editor-typing'] = ({ typing, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setTypings', typing)

              this.context.dispatch('socket/checkSocket', undefined, { root: true })
            }
          }
          const editorTypingAddedListener: ServerEvents['editor-typing-added'] = ({ typing, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setTyping', typing)
            }
          }
          const editorTypingModifiedListener: ServerEvents['editor-typing-modified'] = ({ typing, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setTyping', typing)
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('editor-locations', editorLocationsListener)
            this.socket.off('editor-location-added', editorLocationAddedListener)
            this.socket.off('editor-location-modified', editorLocationModifiedListener)

            this.socket.off('editor-typing', editorTypingListener)
            this.socket.off('editor-typing-added', editorTypingAddedListener)
            this.socket.off('editor-typing-modified', editorTypingModifiedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('editor-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('editor locations unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('editor-locations', editorLocationsListener)
          this.socket.on('editor-location-added', editorLocationAddedListener)
          this.socket.on('editor-location-modified', editorLocationModifiedListener)

          this.socket.on('editor-typing', editorTypingListener)
          this.socket.on('editor-typing-added', editorTypingAddedListener)
          this.socket.on('editor-typing-modified', editorTypingModifiedListener)

          this.context.commit('setEditorSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe
          }))

          resolve()
        } else {
          const error = err || 'Editor locations subscription not provided'
          this.context.commit('setEditorSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  editorLocationUpdate ({ landscapeId, location }: { landscapeId: string, location: EditorLocationUpdate }) {
    const socket: Socket | undefined = this.context.rootState.socket.socket
    socket?.emit('editor-location-update', { landscapeId, location })

    const userId = this.context.rootState.user.user?.id
    if (userId) {
      const locationUpdate: EditorLocation = {
        publishedAt: Math.round(Date.now() / 1000),
        userId
      }
      if (location.versionId) {
        locationUpdate.versionId = location.versionId
      }
      if (location.diagramId) {
        locationUpdate.diagramId = location.diagramId
      }
      this.context.commit('setLocation', locationUpdate)
    }
  }

  @Action({ rawError: true })
  editorTypingUpdate ({ landscapeId, typing }: { landscapeId: string, typing: EditorTypingUpdate }) {
    const socket: Socket | undefined = this.context.rootState.socket.socket
    socket?.emit('editor-typing-update', { landscapeId, typing })

    const userId = this.context.rootState.user.user?.id
    if (userId) {
      const typingUpdate: EditorTyping = {
        publishedAt: Math.round(Date.now() / 1000),
        userId
      }
      if (typing.id) {
        typingUpdate.id = typing.id
      }
      if (typing.value) {
        typingUpdate.value = typing.value
      }
      this.context.commit('setTyping', typingUpdate)
    }
  }
}
