import { differenceInDays, isToday, isTomorrow, isYesterday } from 'date-fns'

import { time } from '@/helpers/filters'

export const daysAgo = (value: string | number | Date | undefined) => {
  if (!value) {
    return ''
  }
  const date = new Date(value)
  const days = differenceInDays(new Date(), date)
  return isToday(date) ? 'Today' : isYesterday(date) ? 'Yesterday' : `${days} day${days === 1 ? '' : 's'} ago`
}

export const inDays = (value: string | number | Date | undefined) => {
  if (!value) {
    return ''
  }
  const date = new Date(value)
  const days = differenceInDays(date, new Date())
  return isToday(date) ? 'Today' : isTomorrow(date) ? 'Tomorrow' : `In ${days} day${days === 1 ? '' : 's'}`
}

export const daysAgoWithTime = (value: string | number | Date | undefined) => {
  if (!value) {
    return ''
  }
  return `${daysAgo(value)} at ${time(value)}`
}
