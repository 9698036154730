import * as fabMarkdown from '@fortawesome/free-brands-svg-icons/faMarkdown'
import * as fabYoutube from '@fortawesome/free-brands-svg-icons/faYoutube'
import * as farCircle from '@fortawesome/free-regular-svg-icons/faCircle'
import * as farClock from '@fortawesome/free-regular-svg-icons/faClock'
import * as farCopy from '@fortawesome/free-regular-svg-icons/faCopy'
import * as farEye from '@fortawesome/free-regular-svg-icons/faEye'
import * as farFileAlt from '@fortawesome/free-regular-svg-icons/faFileAlt'
import * as farSquare from '@fortawesome/free-regular-svg-icons/faSquare'
import * as fasAngleDoubleDown from '@fortawesome/free-solid-svg-icons/faAngleDoubleDown'
import * as fasAngleDoubleLeft from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft'
import * as fasAngleDoubleRight from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight'
import * as fasAngleLeft from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import * as fasArrowsAltH from '@fortawesome/free-solid-svg-icons/faArrowsAltH'
import * as fasBinoculars from '@fortawesome/free-solid-svg-icons/faBinoculars'
import * as fasBolt from '@fortawesome/free-solid-svg-icons/faBolt'
import * as fasBug from '@fortawesome/free-solid-svg-icons/faBug'
import * as fasCalendarCheck from '@fortawesome/free-solid-svg-icons/faCalendarCheck'
import * as fasCalendarTimes from '@fortawesome/free-solid-svg-icons/faCalendarTimes'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCaretUp from '@fortawesome/free-solid-svg-icons/faCaretUp'
import * as fasCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as fasCheckCircle from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import * as fasCheckSquare from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import * as fasChevronDown from '@fortawesome/free-solid-svg-icons/faChevronDown'
import * as fasChevronLeft from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import * as fasChevronRight from '@fortawesome/free-solid-svg-icons/faChevronRight'
import * as fasChevronUp from '@fortawesome/free-solid-svg-icons/faChevronUp'
import * as fasCircle from '@fortawesome/free-solid-svg-icons/faCircle'
import * as fasClock from '@fortawesome/free-solid-svg-icons/faClock'
import * as fasClone from '@fortawesome/free-solid-svg-icons/faClone'
import * as fasCloud from '@fortawesome/free-solid-svg-icons/faCloud'
import * as fasCog from '@fortawesome/free-solid-svg-icons/faCog'
import * as fasCopy from '@fortawesome/free-solid-svg-icons/faCopy'
import * as fasDatabase from '@fortawesome/free-solid-svg-icons/faDatabase'
import * as fasExclamationTriangle from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import * as fasExternalLinkAlt from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import * as fasEye from '@fortawesome/free-solid-svg-icons/faEye'
import * as fasEyeSlash from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import * as fasFile from '@fortawesome/free-solid-svg-icons/faFile'
import * as fasFileAlt from '@fortawesome/free-solid-svg-icons/faFileAlt'
import * as fasFileImage from '@fortawesome/free-solid-svg-icons/faFileImage'
import * as fasFilePdf from '@fortawesome/free-solid-svg-icons/faFilePdf'
import * as fasFolder from '@fortawesome/free-solid-svg-icons/faFolder'
import * as fasFolderMinus from '@fortawesome/free-solid-svg-icons/faFolderMinus'
import * as fasFolderPlus from '@fortawesome/free-solid-svg-icons/faFolderPlus'
import * as fasGlobe from '@fortawesome/free-solid-svg-icons/faGlobe'
import * as fasHandPaper from '@fortawesome/free-solid-svg-icons/faHandPaper'
import * as fasHashtag from '@fortawesome/free-solid-svg-icons/faHashtag'
import * as fasInfo from '@fortawesome/free-solid-svg-icons/faInfo'
import * as fasInfoCircle from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as fasLaptopCode from '@fortawesome/free-solid-svg-icons/faLaptopCode'
import * as fasLightbulb from '@fortawesome/free-solid-svg-icons/faLightbulb'
import * as fasLongArrowAltRight from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight'
import * as fasMapMarkedAlt from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import * as fasMapMarkerAlt from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import * as fasMapPin from '@fortawesome/free-solid-svg-icons/faMapPin'
import * as fasMicrochip from '@fortawesome/free-solid-svg-icons/faMicrochip'
import * as fasMinus from '@fortawesome/free-solid-svg-icons/faMinus'
import * as fasMinusCircle from '@fortawesome/free-solid-svg-icons/faMinusCircle'
import * as fasMobileAlt from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import * as fasMousePointer from '@fortawesome/free-solid-svg-icons/faMousePointer'
import * as fasNetworkWired from '@fortawesome/free-solid-svg-icons/faNetworkWired'
import * as fasPalette from '@fortawesome/free-solid-svg-icons/faPalette'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasPlusCircle from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import * as fasPoo from '@fortawesome/free-solid-svg-icons/faPoo'
import * as fasProjectDiagram from '@fortawesome/free-solid-svg-icons/faProjectDiagram'
import * as fasQuestionCircle from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import * as fasRedo from '@fortawesome/free-solid-svg-icons/faRedo'
import * as fasRobot from '@fortawesome/free-solid-svg-icons/faRobot'
import * as fasRocket from '@fortawesome/free-solid-svg-icons/faRocket'
import * as fasSearchMinus from '@fortawesome/free-solid-svg-icons/faSearchMinus'
import * as fasSearchPlus from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import * as fasServer from '@fortawesome/free-solid-svg-icons/faServer'
import * as fasShare from '@fortawesome/free-solid-svg-icons/faShare'
import * as fasShoppingCart from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import * as fasSitemap from '@fortawesome/free-solid-svg-icons/faSitemap'
import * as fasSnowman from '@fortawesome/free-solid-svg-icons/faSnowman'
import * as fasSquare from '@fortawesome/free-solid-svg-icons/faSquare'
import * as fasStar from '@fortawesome/free-solid-svg-icons/faStar'
import * as fasTags from '@fortawesome/free-solid-svg-icons/faTags'
import * as fasTh from '@fortawesome/free-solid-svg-icons/faTh'
import * as fasThLarge from '@fortawesome/free-solid-svg-icons/faThLarge'
import * as fasThumbsDown from '@fortawesome/free-solid-svg-icons/faThumbsDown'
import * as fasToolbox from '@fortawesome/free-solid-svg-icons/faToolbox'
import * as fasTrashAlt from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import * as fasUndo from '@fortawesome/free-solid-svg-icons/faUndo'
import * as fasUnlink from '@fortawesome/free-solid-svg-icons/faUnlink'
import * as fasUser from '@fortawesome/free-solid-svg-icons/faUser'
import * as fasUserCog from '@fortawesome/free-solid-svg-icons/faUserCog'
import * as fasUserEdit from '@fortawesome/free-solid-svg-icons/faUserEdit'
import * as fasUserPlus from '@fortawesome/free-solid-svg-icons/faUserPlus'
import * as fasVideo from '@fortawesome/free-solid-svg-icons/faVideo'
import * as fasWifi from '@fortawesome/free-solid-svg-icons/faWifi'

export default [
  fasClock.definition,
  fabYoutube.definition,
  fabMarkdown.definition,
  farCircle.definition,
  farClock.definition,
  farCopy.definition,
  farEye.definition,
  farFileAlt.definition,
  farSquare.definition,
  fasAngleDoubleDown.definition,
  fasAngleDoubleLeft.definition,
  fasAngleDoubleRight.definition,
  fasAngleLeft.definition,
  fasArrowsAltH.definition,
  fasBinoculars.definition,
  fasBolt.definition,
  fasBug.definition,
  fasCalendarCheck.definition,
  fasCalendarTimes.definition,
  fasCaretDown.definition,
  fasCaretUp.definition,
  fasCheck.definition,
  fasCheckCircle.definition,
  fasCheckSquare.definition,
  fasChevronDown.definition,
  fasChevronLeft.definition,
  fasChevronRight.definition,
  fasChevronUp.definition,
  fasCircle.definition,
  fasClone.definition,
  fasCloud.definition,
  fasCog.definition,
  fasCopy.definition,
  fasDatabase.definition,
  fasExclamationTriangle.definition,
  fasExternalLinkAlt.definition,
  fasEye.definition,
  fasEyeSlash.definition,
  fasFile.definition,
  fasFileAlt.definition,
  fasFileImage.definition,
  fasFilePdf.definition,
  fasFolder.definition,
  fasFolderMinus.definition,
  fasFolderPlus.definition,
  fasGlobe.definition,
  fasHandPaper.definition,
  fasHashtag.definition,
  fasInfo.definition,
  fasInfoCircle.definition,
  fasLaptopCode.definition,
  fasLightbulb.definition,
  fasLongArrowAltRight.definition,
  fasMapMarkedAlt.definition,
  fasMapMarkerAlt.definition,
  fasMapPin.definition,
  fasMicrochip.definition,
  fasMinus.definition,
  fasMinusCircle.definition,
  fasMobileAlt.definition,
  fasMousePointer.definition,
  fasNetworkWired.definition,
  fasPalette.definition,
  fasPencilAlt.definition,
  fasPlus.definition,
  fasPlusCircle.definition,
  fasPoo.definition,
  fasProjectDiagram.definition,
  fasQuestionCircle.definition,
  fasRedo.definition,
  fasRobot.definition,
  fasRocket.definition,
  fasSearchMinus.definition,
  fasSearchPlus.definition,
  fasServer.definition,
  fasShare.definition,
  fasShoppingCart.definition,
  fasSitemap.definition,
  fasSnowman.definition,
  fasSquare.definition,
  fasStar.definition,
  fasTags.definition,
  fasTh.definition,
  fasThLarge.definition,
  fasThumbsDown.definition,
  fasToolbox.definition,
  fasTrashAlt.definition,
  fasUndo.definition,
  fasUnlink.definition,
  fasUser.definition,
  fasUserCog.definition,
  fasUserEdit.definition,
  fasUserPlus.definition,
  fasVideo.definition,
  fasWifi.definition
] as const
