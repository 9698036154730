import { Domain, DomainPartial, DomainRequired, DomainsService, SocketClientDomainsSubscribeBody } from '@icepanel/platform-api-client'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import getFirestoreId from '@/helpers/firestore-id'
import randomId from '@/helpers/random-id'
import Status from '@/helpers/status'
import { ServerEvents, Socket } from '@/plugins/socket'

export interface IDomainModule {
  domainsCurrent: Record<string, Domain>
  domainsCache: Record<string, Domain> | null
  domainsCommit: Record<string, number>

  domainsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>
}

const name = 'domain'

@Module({
  name,
  namespaced: true
})
export class DomainModule extends VuexModule implements IDomainModule {
  static namespace = name

  domainsCurrent: Record<string, Domain> = {}
  domainsCache: Record<string, Domain> | null = null
  domainsCommit: Record<string, number> = {}

  domainsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void }>()

  get generateDomainId () {
    return () => getFirestoreId('domain')
  }

  get generateDomain () {
    return (landscapeId: string, versionId: string, props: DomainRequired, id = this.generateDomainId()): { domain: Domain, domainUpsert: DomainRequired & DomainPartial } => {
      const commit = typeof this.domainsCommit[id] === 'number' ? this.domainsCommit[id] + 1 : props.commit || 0
      const defaultHandleId = randomId()
      return {
        domain: {
          handleId: defaultHandleId,
          index: 1000000,
          labels: {},
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id,
          landscapeId,
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        domainUpsert: {
          handleId: defaultHandleId,
          labels: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateDomainCommit () {
    return (id: string, props: Omit<DomainPartial, 'commit'>): { domain: Domain, domainUpdate: DomainPartial } => {
      const domain = structuredClone(this.domains[id])
      if (domain) {
        const commit = domain.commit + 1
        domain.updatedAt = new Date().toISOString()
        domain.updatedBy = 'user'
        domain.updatedById = this.context.rootState.user.user?.id || ''

        return {
          domain: {
            ...domain,
            ...props,
            commit
          },
          domainUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find domain ${id}`)
      }
    }
  }

  get domains () {
    return this.domainsCache || this.domainsCurrent
  }

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setDomains (domains: Domain[] | Record<string, Domain>) {
    this.domainsCurrent = Object
      .values(domains)
      .reduce<IDomainModule['domainsCurrent']>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
    this.domainsCommit = Object.values(this.domainsCurrent).reduce<IDomainModule['domainsCommit']>((p, c) => ({
      ...p,
      [c.id]: c.commit
    }), {})
  }

  @Mutation
  setDomainsCache (domainsCache: Record<string, Domain> | null) {
    this.domainsCache = domainsCache
  }

  @Mutation
  setDomain (domain: Domain) {
    Vue.set(this.domainsCurrent, domain.id, domain)
    Vue.set(this.domainsCommit, domain.id, domain.commit)
  }

  @Mutation
  setDomainVersion (domain: Domain) {
    if (
      this.domainsCommit[domain.id] === undefined ||
      domain.commit > this.domainsCommit[domain.id] ||
      (domain.commit === this.domainsCommit[domain.id] && this.domainsCurrent[domain.id] && domain.version > this.domainsCurrent[domain.id].version)
    ) {
      Vue.set(this.domainsCurrent, domain.id, domain)
      Vue.set(this.domainsCommit, domain.id, domain.commit)
    }
  }

  @Mutation
  removeDomain (domain: string | Pick<Domain, 'id' | 'commit'>) {
    if (typeof domain === 'string') {
      Vue.delete(this.domainsCurrent, domain)
    } else if (domain.commit >= this.domainsCommit[domain.id]) {
      Vue.delete(this.domainsCurrent, domain.id)
      this.domainsCommit[domain.id] = domain.commit
    }
  }

  @Mutation
  resetDomains () {
    this.domainsCurrent = {}
    this.domainsCache = null
    this.domainsCommit = {}
  }

  @Mutation
  setDomainsSubscriptionStatus (...params: Parameters<typeof this.domainsSubscriptionStatus.set>) {
    this.domainsSubscriptionStatus.set(...params)
  }

  @Mutation
  domainsUnsubscribe () {
    this.domainsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.domainsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.domainsSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  async domainsSubscribe (body: SocketClientDomainsSubscribeBody & { reconnect: boolean }) {
    this.domainsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.domainsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setDomainsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: Domain[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('domains-subscribe', {
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const domainInitialValueListener: ServerEvents['domain-initial-value'] = ({ domains, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...domains)

              if (finalValue) {
                this.context.commit('setDomains', initialValue)

                this.context.commit('setDomainsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const domainAddedListener: ServerEvents['domain-added'] = ({ domain, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDomainVersion', domain)
            }
            if (domain.createdById && domain.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const domainModifiedListener: ServerEvents['domain-modified'] = ({ domain, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDomainVersion', domain)
            }
            if (domain.updatedById && domain.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const domainRemovedListener: ServerEvents['domain-removed'] = ({ domain, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeDomain', domain)
            }
            if (domain.deletedById && domain.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('domain-initial-value', domainInitialValueListener)
            this.socket.off('domain-added', domainAddedListener)
            this.socket.off('domain-modified', domainModifiedListener)
            this.socket.off('domain-removed', domainRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('domains-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('domains unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('domain-initial-value', domainInitialValueListener)
          this.socket.on('domain-added', domainAddedListener)
          this.socket.on('domain-modified', domainModifiedListener)
          this.socket.on('domain-removed', domainRemovedListener)

          this.context.commit('setDomainsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Domain subscription not provided'
          this.context.commit('setDomainsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async domainCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: DomainRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { domain } = await DomainsService.domainCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setDomainVersion', domain)
    return domain
  }

  @Action({ rawError: true })
  async domainUpsert ({ landscapeId, versionId, domainId, props }: { landscapeId: string, versionId: string, domainId: string, props: DomainRequired & DomainPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { domain } = await DomainsService.domainUpsert(authorization, landscapeId, versionId, domainId, props)
    this.context.commit('setDomainVersion', domain)
    return domain
  }

  @Action({ rawError: true })
  async domainUpdate ({ landscapeId, versionId, domainId, props }: { landscapeId: string, versionId: string, domainId: string, props: DomainPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { domain } = await DomainsService.domainUpdate(authorization, landscapeId, versionId, domainId, props)
    this.context.commit('setDomainVersion', domain)
    return domain
  }

  @Action({ rawError: true })
  async domainDelete ({ landscapeId, versionId, domainId }: { landscapeId: string, versionId: string, domainId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await DomainsService.domainDelete(authorization, landscapeId, versionId, domainId)
    this.context.commit('removeDomain', { commit, id: domainId })
  }
}
