import * as analytics from '@icepanel/app-analytics'
import { DiagramType, ModelObjectType, Task } from '@icepanel/platform-api-client'

export const editorTaskCount: analytics.Property<number> = {
  displayName: 'Editor activity commit count',
  name: 'editor_activity_commit_count',
  required: true,
  type: 'number'
}
export const editorTaskTypes: analytics.Property<Task['type'][]> = {
  displayName: 'Editor activity commit types',
  examples: [['comment-create', 'comment-delete', 'comment-update', 'diagram-create', 'diagram-delete', 'diagram-update', 'flow-create', 'flow-delete', 'flow-update']],
  name: 'editor_activity_commit_types',
  required: true,
  type: 'array'
}
export const editorHelpDialogPage: analytics.Property<'introduction' | 'modelling' | 'c4-model' | 'collaboration' | 'visual-storytelling'> = {
  displayName: 'Editor help dialog page',
  examples: ['introduction', 'modelling', 'c4-model', 'collaboration', 'visual-storytelling'],
  name: 'editor_help_dialog_page',
  required: true,
  type: 'string'
}
export const editorDocumentationObjectType: analytics.Property<DiagramType | ModelObjectType | 'connection'> = {
  displayName: 'Editor documentation object type',
  examples: ['context-diagram', 'app-diagram', 'component-diagram', 'actor', 'app', 'group', 'component', 'store', 'system', 'connection'],
  name: 'editor_documentation_object_type',
  required: true,
  type: 'string'
}
export const editorDocumentationType: analytics.Property<'rich-text' | 'markdown'> = {
  displayName: 'Editor documentation type',
  examples: ['rich-text', 'markdown'],
  name: 'editor_documentation_type',
  required: true,
  type: 'string'
}
export const editorDocumentationBlockquoteCount: analytics.Property<number> = {
  displayName: 'Editor documentation blockquote count',
  name: 'editor_documentation_blockquote_count',
  required: true,
  type: 'number'
}
export const editorDocumentationCodeCount: analytics.Property<number> = {
  displayName: 'Editor documentation code count',
  name: 'editor_documentation_code_count',
  required: true,
  type: 'number'
}
export const editorDocumentationDividerCount: analytics.Property<number> = {
  displayName: 'Editor documentation divider count',
  name: 'editor_documentation_divider_count',
  required: true,
  type: 'number'
}
export const editorDocumentationEmphasisCount: analytics.Property<number> = {
  displayName: 'Editor documentation emphasis count',
  name: 'editor_documentation_emphasis_count',
  required: true,
  type: 'number'
}
export const editorDocumentationHeading1Count: analytics.Property<number> = {
  displayName: 'Editor documentation heading 1 count',
  name: 'editor_documentation_heading_1_count',
  required: true,
  type: 'number'
}
export const editorDocumentationHeading2Count: analytics.Property<number> = {
  displayName: 'Editor documentation heading 2 count',
  name: 'editor_documentation_heading_2_count',
  required: true,
  type: 'number'
}
export const editorDocumentationHeading3Count: analytics.Property<number> = {
  displayName: 'Editor documentation heading 3 count',
  name: 'editor_documentation_heading_3_count',
  required: true,
  type: 'number'
}
export const editorDocumentationHeading4Count: analytics.Property<number> = {
  displayName: 'Editor documentation heading 4 count',
  name: 'editor_documentation_heading_4_count',
  required: true,
  type: 'number'
}
export const editorDocumentationHeading5Count: analytics.Property<number> = {
  displayName: 'Editor documentation heading 5 count',
  name: 'editor_documentation_heading_5_count',
  required: true,
  type: 'number'
}
export const editorDocumentationHeading6Count: analytics.Property<number> = {
  displayName: 'Editor documentation heading 6 count',
  name: 'editor_documentation_heading_6_count',
  required: true,
  type: 'number'
}
export const editorDocumentationImageCount: analytics.Property<number> = {
  displayName: 'Editor documentation image count',
  name: 'editor_documentation_image_count',
  required: true,
  type: 'number'
}
export const editorDocumentationInlineCodeCount: analytics.Property<number> = {
  displayName: 'Editor documentation inline code count',
  name: 'editor_documentation_inline_code_count',
  required: true,
  type: 'number'
}
export const editorDocumentationLinkCount: analytics.Property<number> = {
  displayName: 'Editor documentation link count',
  name: 'editor_documentation_link_count',
  required: true,
  type: 'number'
}
export const editorDocumentationListCount: analytics.Property<number> = {
  displayName: 'Editor documentation list count',
  name: 'editor_documentation_list_count',
  required: true,
  type: 'number'
}
export const editorDocumentationListItemCount: analytics.Property<number> = {
  displayName: 'Editor documentation list item count',
  name: 'editor_documentation_list_item_count',
  required: true,
  type: 'number'
}
export const editorDocumentationParagraphCount: analytics.Property<number> = {
  displayName: 'Editor documentation paragraph count',
  name: 'editor_documentation_paragraph_count',
  required: true,
  type: 'number'
}
export const editorDocumentationStrikethroughCount: analytics.Property<number> = {
  displayName: 'Editor documentation strikethrough count',
  name: 'editor_documentation_strikethrough_count',
  required: true,
  type: 'number'
}
export const editorDocumentationStrongCount: analytics.Property<number> = {
  displayName: 'Editor documentation strong count',
  name: 'editor_documentation_strong_count',
  required: true,
  type: 'number'
}
export const editorDocumentationTableCount: analytics.Property<number> = {
  displayName: 'Editor documentation table count',
  name: 'editor_documentation_table_count',
  required: true,
  type: 'number'
}
export const editorDocumentationTableCellCount: analytics.Property<number> = {
  displayName: 'Editor documentation table cell count',
  name: 'editor_documentation_table_cell_count',
  required: true,
  type: 'number'
}
export const editorDocumentationTableRowCount: analytics.Property<number> = {
  displayName: 'Editor documentation table row count',
  name: 'editor_documentation_table_row_count',
  required: true,
  type: 'number'
}
export const editorDocumentationCharacterCount: analytics.Property<number> = {
  displayName: 'Editor documentation character count',
  name: 'editor_documentation_character_count',
  required: true,
  type: 'number'
}
export const editorDocumentationWordCount: analytics.Property<number> = {
  displayName: 'Editor documentation word count',
  name: 'editor_documentation_word_count',
  required: true,
  type: 'number'
}
