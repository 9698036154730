import { ModelConnection, ModelObject, TaskType } from '@icepanel/platform-api-client'
import isEqual from 'lodash/isEqual'

const createIcon = {
  icon: '$fas-plus',
  iconColor: 'green'
} as const

const updateIcon = {
  icon: '$fas-sync',
  iconColor: 'grey lighten-4'
} as const

const removeIcon = {
  icon: '$fas-times',
  iconColor: 'error'
} as const

// const deleteIcon = {
//   icon: '$fas-trash-alt',
//   iconColor: 'error'
// } as const

const focusTextColor = 'white--text'
const errorTextColor = 'error--text'
const disabledTextColor = 'grey--text text--lighten-4'

const wordify = (str: string) => str?.replaceAll('-', ' ').replaceAll('_', '').trim()
const capitalize = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1)

export interface IDiffInput {
  modelConnections: Record<string, ModelConnection>
  modelObjects: Record<string, ModelObject>
}

export interface IDiffChangesCreated {
  modelConnections: Record<string, ModelConnection>
  modelObjects: Record<string, ModelObject>
}

export interface IDiffChangesUpdated {
  modelConnections: Record<string, { before: ModelConnection, after: ModelConnection }>
  modelObjects: Record<string, { before: ModelObject, after: ModelObject }>
}

export interface IDiffChangesRemoved {
  modelConnections: Record<string, ModelConnection>
  modelObjects: Record<string, ModelObject>
}

export interface IDiffChangesDeleted {
  modelConnections: Record<string, ModelConnection>
  modelObjects: Record<string, ModelObject>
}

export interface IDiffChanges {
  created: IDiffChangesCreated
  deleted: IDiffChangesDeleted
  removed: IDiffChangesRemoved
  updated: IDiffChangesUpdated
}

export interface IDiffLink {
  modelConnectionHandleId?: string
  modelObjectHandleId?: string
}

export interface IDiffDescriptionContent {
  icon?: string
  link?: IDiffLink
  text: string
  textColor?: string
  tooltip?: string
}

export interface IDiffDescription {
  content: IDiffDescriptionContent[]
  icon?: string
  iconColor?: string
  id: string
  type: TaskType
}

export const changes = (before: IDiffInput, after: IDiffInput): IDiffChanges => {
  const modelObjectsCreated = Object
    .values(after.modelObjects)
    .filter(o => !before.modelObjects[o.id])
    .reduce<Record<string, ModelObject>>((p, c) => ({
      ...p,
      [c.id]: c
    }), {})

  const modelObjectsUpdated = Object
    .values(after.modelObjects)
    .filter(o => before.modelObjects[o.id] && after.modelObjects[o.id] && after.modelObjects[o.id].status !== 'removed' && !isEqual(before.modelObjects[o.id], after.modelObjects[o.id]))
    .reduce<Record<string, { before: ModelObject, after: ModelObject }>>((p, c) => ({
      ...p,
      [c.id]: {
        after: after.modelObjects[c.id],
        before: before.modelObjects[c.id]
      }
    }), {})

  const modelObjectsRemoved = Object
    .values(before.modelObjects)
    .filter(o => before.modelObjects[o.id] && after.modelObjects[o.id] && before.modelObjects[o.id].status !== 'removed' && after.modelObjects[o.id].status === 'removed')
    .reduce<Record<string, ModelObject>>((p, c) => ({
      ...p,
      [c.id]: c
    }), {})

  const modelObjectsDeleted = Object
    .values(before.modelObjects)
    .filter(o => !after.modelObjects[o.id])
    .reduce<Record<string, ModelObject>>((p, c) => ({
      ...p,
      [c.id]: c
    }), {})

  const modelConnectionsCreated = Object
    .values(after.modelConnections)
    .filter(o => !before.modelConnections[o.id])
    .reduce<Record<string, ModelConnection>>((p, c) => ({
      ...p,
      [c.id]: c
    }), {})

  const modelConnectionsUpdated = Object
    .values(after.modelConnections)
    .filter(o => before.modelConnections[o.id] && after.modelConnections[o.id] && after.modelConnections[o.id].status !== 'removed' && !isEqual(before.modelConnections[o.id], after.modelConnections[o.id]))
    .reduce<Record<string, { before: ModelConnection, after: ModelConnection }>>((p, c) => ({
      ...p,
      [c.id]: {
        after: after.modelConnections[c.id],
        before: before.modelConnections[c.id]
      }
    }), {})

  const modelConnectionsRemoved = Object
    .values(before.modelConnections)
    .filter(o => before.modelConnections[o.id] && after.modelConnections[o.id] && before.modelConnections[o.id].status !== 'removed' && after.modelConnections[o.id].status === 'removed')
    .reduce<Record<string, ModelConnection>>((p, c) => ({
      ...p,
      [c.id]: c
    }), {})

  const modelConnectionsDeleted = Object
    .values(before.modelConnections)
    .filter(o => !after.modelConnections[o.id])
    .reduce<Record<string, ModelConnection>>((p, c) => ({
      ...p,
      [c.id]: c
    }), {})

  return {
    created: {
      modelConnections: modelConnectionsCreated,
      modelObjects: modelObjectsCreated
    },
    deleted: {
      modelConnections: modelConnectionsDeleted,
      modelObjects: modelObjectsDeleted
    },
    removed: {
      modelConnections: modelConnectionsRemoved,
      modelObjects: modelObjectsRemoved
    },
    updated: {
      modelConnections: modelConnectionsUpdated,
      modelObjects: modelObjectsUpdated
    }
  }
}

export const describeChanges = (changes: Partial<IDiffChanges>, after?: IDiffInput): IDiffDescription[] => {
  const modelObjectsCreated = Object.values(changes.created?.modelObjects || {}).map((o): IDiffDescription => {
    const parent = o.parentId ? after?.modelObjects[o.parentId] : undefined
    if (parent && parent.type !== 'root') {
      return {
        ...createIcon,
        content: [{
          link: {
            modelObjectHandleId: o.handleId
          },
          text: o.name || capitalize(wordify(o.type)),
          textColor: focusTextColor,
          tooltip: o.name ? capitalize(wordify(o.type)) : undefined
        }, {
          text: 'inside',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: parent.handleId
          },
          text: parent.name || wordify(parent.type),
          textColor: focusTextColor,
          tooltip: parent.name ? capitalize(wordify(parent.type)) : undefined
        }],
        id: o.id,
        type: 'model-object-create'
      }
    } else {
      return {
        ...createIcon,
        content: [{
          link: {
            modelObjectHandleId: o.handleId
          },
          text: o.name || capitalize(wordify(o.type)),
          textColor: focusTextColor,
          tooltip: o.name ? capitalize(wordify(o.type)) : undefined
        }],
        id: o.id,
        type: 'model-object-create'
      }
    }
  })

  const modelConnectionsCreated = Object.values(changes.created?.modelConnections || {}).map((o): IDiffDescription => {
    const origin = o.originId ? after?.modelObjects[o.originId] : undefined
    const target = o.targetId ? after?.modelObjects[o.targetId] : undefined
    if (origin && target) {
      return {
        ...createIcon,
        content: [{
          text: 'Connect',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: origin.handleId
          },
          text: origin.name || wordify(origin.type),
          textColor: focusTextColor,
          tooltip: origin.name ? capitalize(wordify(origin.type)) : undefined
        }, {
          text: 'to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: target.handleId
          },
          text: target.name || wordify(target.type),
          textColor: focusTextColor,
          tooltip: target.name ? capitalize(wordify(target.type)) : undefined
        }, {
          text: 'via',
          textColor: disabledTextColor
        }, {
          link: {
            modelConnectionHandleId: o.handleId
          },
          text: o.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.name ? 'Connection' : undefined
        }],
        id: o.id,
        type: 'model-connection-create'
      }
    } else {
      return {
        ...createIcon,
        content: [{
          link: {
            modelConnectionHandleId: o.handleId
          },
          text: o.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.name ? 'Connection' : undefined
        }],
        id: o.id,
        type: 'model-connection-create'
      }
    }
  })

  const modelObjectsUpdated = Object.values(changes.updated?.modelObjects || {}).map(o => {
    const afterParent = o.after.parentId ? after?.modelObjects[o.after.parentId] : undefined
    const descriptions: IDiffDescription[] = []

    if (o.before.name !== o.after.name) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.before.name || capitalize(wordify(o.before.type)),
          textColor: focusTextColor,
          tooltip: o.before.name ? capitalize(wordify(o.before.type)) : undefined
        }, {
          text: 'renamed to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || wordify(o.after.type),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (o.before.status !== o.after.status) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'status set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: wordify(o.after.status),
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (o.before.external !== o.after.external) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.external ? 'external' : 'internal',
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (o.before.icon?.name !== o.after.icon?.name) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'icon set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.icon?.name || 'none',
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (!isEqual(o.before.technologies, o.after.technologies)) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'technologies set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: Object.values(o.after.technologies).map(o => o.nameShort || '').join(', '),
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (!isEqual(o.before.groupIds, o.after.groupIds)) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'groups updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (!isEqual(o.before.tagIds, o.after.tagIds)) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'tags updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (!isEqual(o.before.teamIds, o.after.teamIds)) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'teams updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (o.before.caption !== o.after.caption) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'caption updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (o.before.description !== o.after.description) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'description updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (!isEqual(o.before.links, o.after.links)) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'links updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (o.before.parentId !== o.after.parentId && afterParent) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'moved inside',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: afterParent.handleId
          },
          text: afterParent.name || wordify(afterParent.type),
          textColor: focusTextColor,
          tooltip: afterParent.name ? capitalize(wordify(afterParent.type)) : undefined
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    } else if (o.before.parentId !== o.after.parentId) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'moved',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }
    if (o.before.type !== o.after.type) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }, {
          text: 'type set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: wordify(o.after.type),
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }

    if (!descriptions.length) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || capitalize(wordify(o.after.type)),
          textColor: focusTextColor,
          tooltip: o.after.name ? capitalize(wordify(o.after.type)) : undefined
        }],
        id: o.after.id,
        type: 'model-object-update'
      })
    }

    return descriptions
  })

  const modelConnectionsUpdated = Object.values(changes.updated?.modelConnections || {}).map(o => {
    const descriptions: IDiffDescription[] = []

    const afterOrigin = o.after.originId ? after?.modelObjects[o.after.originId] : undefined
    const afterTarget = o.after.targetId ? after?.modelObjects[o.after.targetId] : undefined

    if (o.before.name !== o.after.name) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.before.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.before.name ? 'Connection' : undefined
        }, {
          text: 'renamed to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }
    if (o.before.originId !== o.after.originId && afterOrigin) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }, {
          text: 'sender set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: afterOrigin.handleId
          },
          text: afterOrigin.name || wordify(afterOrigin.type),
          textColor: focusTextColor,
          tooltip: afterOrigin.name ? capitalize(wordify(afterOrigin.type)) : undefined
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }
    if (o.before.targetId !== o.after.targetId && afterTarget) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }, {
          text: 'receiver set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: afterTarget.handleId
          },
          text: afterTarget.name || wordify(afterTarget.type),
          textColor: focusTextColor,
          tooltip: afterTarget.name ? capitalize(wordify(afterTarget.type)) : undefined
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }
    if (o.before.status !== o.after.status) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }, {
          text: 'status set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: wordify(o.after.status),
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }
    if (o.before.direction !== o.after.direction) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }, {
          text: 'direction set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.direction || 'none',
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }
    if (!isEqual(o.before.technologies, o.after.technologies)) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }, {
          text: 'technologies set to',
          textColor: disabledTextColor
        }, {
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: Object.values(o.after.technologies).map(o => o.nameShort || '').join(', '),
          textColor: focusTextColor
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }
    if (!isEqual(o.before.tagIds, o.after.tagIds)) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }, {
          text: 'tags updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }
    if (o.before.description !== o.after.description) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }, {
          text: 'description updated',
          textColor: disabledTextColor
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }

    if (!descriptions.length) {
      descriptions.push({
        ...updateIcon,
        content: [{
          link: {
            modelObjectHandleId: o.after.handleId
          },
          text: o.after.name || 'Connection',
          textColor: focusTextColor,
          tooltip: o.after.name ? 'Connection' : undefined
        }],
        id: o.after.id,
        type: 'model-connection-update'
      })
    }

    return descriptions
  })

  const modelObjectsRemoved = Object.values(changes.removed?.modelObjects || {}).map((o): IDiffDescription => {
    return {
      ...removeIcon,
      content: [{
        link: {
          modelObjectHandleId: o.handleId
        },
        text: o.name || capitalize(wordify(o.type)),
        textColor: focusTextColor,
        tooltip: o.name ? capitalize(wordify(o.type)) : undefined
      }, {
        text: 'status set to',
        textColor: disabledTextColor
      }, {
        text: 'removed',
        textColor: errorTextColor
      }],
      id: o.id,
      type: 'model-object-delete'
    }
  })

  const modelConnectionsRemoved = Object.values(changes.removed?.modelConnections || {}).map((o): IDiffDescription => {
    return {
      ...removeIcon,
      content: [{
        link: {
          modelConnectionHandleId: o.handleId
        },
        text: o.name || 'Connection',
        textColor: focusTextColor,
        tooltip: o.name ? 'Connection' : undefined
      }, {
        text: 'status set to',
        textColor: disabledTextColor
      }, {
        text: 'removed',
        textColor: errorTextColor
      }],
      id: o.id,
      type: 'model-connection-delete'
    }
  })

  return [
    ...modelObjectsCreated,
    ...modelConnectionsCreated,
    ...modelObjectsUpdated.flat(),
    ...modelConnectionsUpdated.flat(),
    ...modelObjectsRemoved,
    ...modelConnectionsRemoved
  ]
}
