import { IconDefinition, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueConstructor from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify/lib'
import { Store } from 'vuex'

import * as env from '@/helpers/env'

import customIcons from './helpers/custom-icons'
import * as filters from './helpers/filters'
import icons from './helpers/icons'
import routes from './routes'
import { EditorModule } from './store'

export const install = ({ vuetify, store, Vue, router, library }: { vuetify: Vuetify, store: Store<any>, Vue: typeof VueConstructor, router: VueRouter, library: Library }) => {
  const iconDefinitions = [...customIcons, ...icons]
  iconDefinitions.forEach(definition => {
    library.add(definition as IconDefinition)

    vuetify.framework.icons.values[`${definition.prefix}-${definition.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [definition.prefix, definition.iconName]
      }
    }
  })

  Vue.filter('daysAgo', filters.daysAgo)
  Vue.filter('daysAgoWithTime', filters.daysAgoWithTime)
  Vue.filter('inDays', filters.inDays)

  store.registerModule(EditorModule.namespace, EditorModule)

  if (!env.IS_SHARE_LINK) {
    router.addRoutes(routes)
  }
}
