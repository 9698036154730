import * as analytics from '@icepanel/app-analytics'

import { diagramType } from '@/modules/diagram/helpers/analytics-properties'
import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'
import { tagGroupIcon, tagGroupName } from '@/modules/tag/helpers/analytics-properties'

import {
  editorDocumentationBlockquoteCount,
  editorDocumentationCharacterCount,
  editorDocumentationCodeCount,
  editorDocumentationDividerCount,
  editorDocumentationEmphasisCount,
  editorDocumentationHeading1Count,
  editorDocumentationHeading2Count,
  editorDocumentationHeading3Count,
  editorDocumentationHeading4Count,
  editorDocumentationHeading5Count,
  editorDocumentationHeading6Count,
  editorDocumentationImageCount,
  editorDocumentationInlineCodeCount,
  editorDocumentationLinkCount,
  editorDocumentationListCount,
  editorDocumentationListItemCount,
  editorDocumentationObjectType,
  editorDocumentationParagraphCount,
  editorDocumentationStrikethroughCount,
  editorDocumentationStrongCount,
  editorDocumentationTableCellCount,
  editorDocumentationTableCount,
  editorDocumentationTableRowCount,
  editorDocumentationType,
  editorDocumentationWordCount,
  editorHelpDialogPage,
  editorTaskCount,
  editorTaskTypes
} from './analytics-properties'

/**
 * Screens
 */

export const editorDiagramScreen = new analytics.Event({
  displayName: 'Editor diagram screen',
  name: 'editor_diagram_screen',
  tags: [analytics.Tags.Screen]
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorFlowScreen = new analytics.Event({
  displayName: 'Editor flow screen',
  name: 'editor_flow_screen',
  tags: [analytics.Tags.Screen]
}, {
  diagramType,
  landscapeId,
  organizationId
})

/**
 * Dialogs
 */

export const editorHelpDialog = new analytics.Event({
  displayName: 'Editor help dialog',
  name: 'editor_help_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  editorHelpDialogPage
})
export const editorDescriptionDialog = new analytics.Event({
  displayName: 'Editor description dialog',
  name: 'editor_description_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  editorDocumentationObjectType,
  landscapeId,
  organizationId
})

/**
 * Links
 */

export const editorC4ModelLink = new analytics.Event({
  displayName: 'Editor C4 model link',
  name: 'editor_c4_model_link',
  tags: [analytics.Tags.Link]
})
export const editorDocsLink = new analytics.Event({
  displayName: 'Editor docs link',
  name: 'editor_docs_link',
  tags: [analytics.Tags.Link]
})
export const editorBlogLink = new analytics.Event({
  displayName: 'Editor blog link',
  name: 'editor_blog_link',
  tags: [analytics.Tags.Link]
})
export const editorContactLink = new analytics.Event({
  displayName: 'Editor contact link',
  name: 'editor_contact_link',
  tags: [analytics.Tags.Link]
})

/**
 * Events
 */

export const editorBackButton = new analytics.Event({
  displayName: 'Editor back button',
  name: 'editor_back_button'
}, {
  diagramType: {
    ...diagramType,
    required: false
  },
  landscapeId,
  organizationId
})
export const editorForwardButton = new analytics.Event({
  displayName: 'Editor forward button',
  name: 'editor_forward_button'
}, {
  diagramType: {
    ...diagramType,
    required: false
  },
  landscapeId,
  organizationId
})
export const editorActivityUndo = new analytics.Event({
  displayName: 'Editor activity undo',
  name: 'editor_activity_undo'
}, {
  editorTaskCount,
  editorTaskTypes,
  landscapeId,
  organizationId
})
export const editorActivityRedo = new analytics.Event({
  displayName: 'Editor activity redo',
  name: 'editor_activity_redo'
}, {
  editorTaskCount,
  editorTaskTypes,
  landscapeId,
  organizationId
})
export const editorDescriptionUpdate = new analytics.Event({
  displayName: 'Editor description update',
  name: 'editor_description_update'
}, {
  editorDocumentationBlockquoteCount,
  editorDocumentationCharacterCount,
  editorDocumentationCodeCount,
  editorDocumentationDividerCount,
  editorDocumentationEmphasisCount,
  editorDocumentationHeading1Count,
  editorDocumentationHeading2Count,
  editorDocumentationHeading3Count,
  editorDocumentationHeading4Count,
  editorDocumentationHeading5Count,
  editorDocumentationHeading6Count,
  editorDocumentationImageCount,
  editorDocumentationInlineCodeCount,
  editorDocumentationLinkCount,
  editorDocumentationListCount,
  editorDocumentationListItemCount,
  editorDocumentationObjectType,
  editorDocumentationParagraphCount,
  editorDocumentationStrikethroughCount,
  editorDocumentationStrongCount,
  editorDocumentationTableCellCount,
  editorDocumentationTableCount,
  editorDocumentationTableRowCount,
  editorDocumentationType,
  editorDocumentationWordCount,
  landscapeId,
  organizationId
})
export const editorDescriptionTemplate = new analytics.Event({
  displayName: 'Editor description template',
  name: 'editor_description_template'
}, {
  editorDocumentationObjectType,
  landscapeId,
  organizationId
})
export const editorDuplicateDiagram = new analytics.Event({
  displayName: 'Editor duplicate diagram',
  name: 'editor_duplicate_diagram'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorDrawerExpand = new analytics.Event({
  displayName: 'Editor drawer expand',
  name: 'editor_drawer_expand'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorDrawerCollapse = new analytics.Event({
  displayName: 'Editor drawer collapse',
  name: 'editor_drawer_collapse'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorHelpVideoPlay = new analytics.Event({
  displayName: 'Editor help video play',
  name: 'editor_help_video_play'
}, {
  editorHelpDialogPage
})
export const editorHelpVideoEnd = new analytics.Event({
  displayName: 'Editor help video end',
  name: 'editor_help_video_end'
}, {
  editorHelpDialogPage
})
export const editorOverlayBarTags = new analytics.Event({
  displayName: 'Editor overlay bar tags',
  name: 'editor_overlay_bar_tags'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorOverlayBarTechnology = new analytics.Event({
  displayName: 'Editor overlay bar technology',
  name: 'editor_overlay_bar_technology'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorOverlayBarStatus = new analytics.Event({
  displayName: 'Editor overlay bar status',
  name: 'editor_overlay_bar_status'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorOverlayBarTagGroup = new analytics.Event({
  displayName: 'Editor overlay bar tag group',
  name: 'editor_overlay_bar_tag_group'
}, {
  diagramType,
  landscapeId,
  organizationId,
  tagGroupIcon,
  tagGroupName
})
export const editorOverlayBarTeam = new analytics.Event({
  displayName: 'Editor overlay bar team',
  name: 'editor_overlay_bar_team'
}, {
  diagramType,
  landscapeId,
  organizationId
})

/**
 * Hidden
 */

export const editorModelScreenHidden = new analytics.Event({
  displayName: 'Editor model screen',
  hidden: true,
  name: 'editor_model_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const editorModelObjectLibraryMenuHidden = new analytics.Event({
  displayName: 'Editor model object library menu',
  hidden: true,
  name: 'editor_model_object_library_menu',
  tags: [analytics.Tags.Menu]
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorDrawerObjectExpandHidden = new analytics.Event({
  displayName: 'Editor drawer object expand',
  hidden: true,
  name: 'editor_drawer_object_expand'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorDrawerObjectCollapseHidden = new analytics.Event({
  displayName: 'Editor drawer object collapse',
  hidden: true,
  name: 'editor_drawer_object_collapse'
}, {
  diagramType,
  landscapeId,
  organizationId
})
export const editorTrainingBookingMenuHidden = new analytics.Event({
  displayName: 'Editor training booking menu',
  hidden: true,
  name: 'editor_training_booking_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const editorTrainingBookingPayHidden = new analytics.Event({
  displayName: 'Editor training booking pay',
  hidden: true,
  name: 'editor_training_booking_pay'
}, {
  landscapeId,
  organizationId
})
export const editorDiagramReviewMenuHidden = new analytics.Event({
  displayName: 'Editor diagram review menu',
  hidden: true,
  name: 'editor_diagram_review_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const editorGetDiagramReviewHidden = new analytics.Event({
  displayName: 'Editor get diagram review',
  hidden: true,
  name: 'editor_get_diagram_review'
}, {
  diagramType,
  landscapeId,
  organizationId
})
