
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'EditorHelpDialog'
})
export default class extends Vue {
  userModule = getModule(UserModule, this.$store)

  containerScroll = 0

  get currentGuideId () {
    return this.$queryValue('editor_help_dialog')
  }

  get currentGuide () {
    return this.guides.find(o => o.to.query.editor_help_dialog === this.currentGuideId)
  }

  get guides () {
    return [
      {
        caption: 'Introduction',
        icon: '$custom-duotone-flag-checkered',
        id: 'introduction',
        title: 'Why IcePanel is different',
        to: {
          query: this.$setQuery({
            editor_help_dialog: 'introduction'
          })
        },
        video: 'g6VYaxnnWs8'
      },
      {
        caption: 'Step 1',
        icon: '$custom-duotone-layer-group',
        id: 'c4-model',
        image: require('../assets/what-is-c4.svg'),
        imageAspectRatio: 2.2648752399,
        text: 'Hey and welcome! I’m Jacob - Co-Founder of IcePanel.\n\nIcePanel is different from the diagramming tools you might be used to.\n\nFirstly we’re a Modelling tool, meaning when you create objects or relationships between objects they’re stored in a source of truth, a model, allowing you to reuse objects and update them in many diagrams.\n\nSecondly, we’re built on the C4 model a lightweight consistent language to explain software systems with a layered approach.\n\nThese combined in an accessible tool, give you the ability to create a shared knowledge base across dev teams, onboard faster and bridge the gap between engineering and design.',
        title: 'The C4 model',
        to: {
          query: this.$setQuery({
            editor_help_dialog: 'c4-model'
          })
        },
        video: 'nUVG0zPiO64'
      },
      {
        caption: 'Step 2',
        icon: '$custom-duotone-sitemap',
        id: 'modelling',
        text: 'Hey and welcome! I’m Jacob - Co-Founder of IcePanel.\n\nIcePanel is different from the diagramming tools you might be used to.\n\nFirstly we’re a Modelling tool, meaning when you create objects or relationships between objects they’re stored in a source of truth, a model, allowing you to reuse objects and update them in many diagrams.\n\nSecondly, we’re built on the C4 model a lightweight consistent language to explain software systems with a layered approach.\n\nThese combined in an accessible tool, give you the ability to create a shared knowledge base across dev teams, onboard faster and bridge the gap between engineering and design.',
        title: 'Understanding modelling',
        to: {
          query: this.$setQuery({
            editor_help_dialog: 'modelling'
          })
        },
        video: 'b1hdvoX02m4'
      },
      {
        caption: 'Step 3',
        icon: '$custom-duotone-users',
        id: 'collaboration',
        text: 'Hey and welcome! I’m Jacob - Co-Founder of IcePanel.\n\nIcePanel is different from the diagramming tools you might be used to.\n\nFirstly we’re a Modelling tool, meaning when you create objects or relationships between objects they’re stored in a source of truth, a model, allowing you to reuse objects and update them in many diagrams.\n\nSecondly, we’re built on the C4 model a lightweight consistent language to explain software systems with a layered approach.\n\nThese combined in an accessible tool, give you the ability to create a shared knowledge base across dev teams, onboard faster and bridge the gap between engineering and design.',
        title: 'Collaboration',
        to: {
          query: this.$setQuery({
            editor_help_dialog: 'collaboration'
          })
        },
        video: 'pPzBYQAw7os'
      },
      {
        caption: 'Step 4',
        icon: '$custom-duotone-bolt',
        id: 'visual-storytelling',
        text: 'Hey and welcome! I’m Jacob - Co-Founder of IcePanel.\n\nIcePanel is different from the diagramming tools you might be used to.\n\nFirstly we’re a Modelling tool, meaning when you create objects or relationships between objects they’re stored in a source of truth, a model, allowing you to reuse objects and update them in many diagrams.\n\nSecondly, we’re built on the C4 model a lightweight consistent language to explain software systems with a layered approach.\n\nThese combined in an accessible tool, give you the ability to create a shared knowledge base across dev teams, onboard faster and bridge the gap between engineering and design.',
        title: 'Visual storytelling',
        to: {
          query: this.$setQuery({
            editor_help_dialog: 'visual-storytelling'
          })
        },
        video: 'bwLCftvZqsM'
      }
    ] as const
  }

  @Watch('currentGuide')
  onCurrentGuideChanged (currentGuide: this['currentGuide'], prevCurrentGuide: this['currentGuide']) {
    if (currentGuide && currentGuide?.id !== prevCurrentGuide?.id) {
      analytics.editorHelpDialog.track(this, {
        editorHelpDialogPage: currentGuide.id
      })
    }
  }

  playing () {
    if (this.currentGuide) {
      analytics.editorHelpVideoPlay.track(this, {
        editorHelpDialogPage: this.currentGuide.id
      })
    }
  }

  ended () {
    if (this.currentGuide) {
      analytics.editorHelpVideoEnd.track(this, {
        editorHelpDialogPage: this.currentGuide.id
      })
    }
  }
}
