import { IconDefinition, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuetify from 'vuetify/lib'
import { Store } from 'vuex'

import icons from './helpers/icons'
import { DomainModule } from './store'

export const install = ({ vuetify, store, library }: { vuetify: Vuetify, store: Store<any>, library: Library }) => {
  icons.forEach(definition => {
    library.add(definition as IconDefinition)

    vuetify.framework.icons.values[`${definition.prefix}-${definition.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [definition.prefix, definition.iconName]
      }
    }
  })

  store.registerModule(DomainModule.namespace, DomainModule)
}
