
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import { SupportModule } from '@/modules/support/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../../helpers/analytics'
import { EditorModule } from '../../store'
import HelpDialog from '../help-dialog.vue'

@Component({
  components: {
    HelpDialog
  },
  name: 'EditorAppbarHelp'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  supportModule = getModule(SupportModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  get links () {
    return [
      {
        click: () => analytics.editorDocsLink.track(this),
        href: 'https://docs.icepanel.io',
        icon: '$custom-duotone-book',
        id: 'documentation',
        target: '_blank',
        text: 'Documentation'
      },
      {
        click: () => {},
        icon: '$custom-duotone-flag-checkered',
        id: 'getting-started',
        replace: true,
        text: 'Getting started',
        to: { query: this.$setQuery({ editor_help_dialog: 'introduction' }) }
      },
      {
        click: () => analytics.editorBlogLink.track(this),
        href: env.IS_PRODUCTION ? 'https://icepanel.io/blog' : 'https://icepanel.dev/blog',
        icon: '$custom-duotone-book-open',
        id: 'articles',
        target: '_blank',
        text: 'Articles'
      },
      {
        click: () => {},
        icon: '$custom-duotone-gift',
        id: 'whats-new',
        replace: true,
        text: 'What\'s new?',
        to: { query: this.$setQuery({ whats_new_dialog: '1' }) }
      },
      {
        click: () => this.supportModule.setOpen(true),
        icon: '$custom-duotone-user-headset',
        id: 'contact-us',
        text: 'Contact us'
      }
    ]
  }
}
